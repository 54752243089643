export const TOKENTYPE = [
  //Velas
  {
    name: 'Velas',
    symbol: 'VLX',
    unit: '18',
    contractAddress: '0xE9C803F48dFFE50180Bd5B01dC04DA939E3445Fc',
    signature:
      '1203564c5800000000E9C803F48dFFE50180Bd5B01dC04DA939E3445Fc304502205851A8725F869690B28F716DE887D0AB2651E2CF35852BF0DCBEAAF96C101967022100A8011B8B4762413525AF4F1B63C69B6C2FD05E6FDAFA13B4A3AA915A1C01967B',
  },
  //NEAR
  {
    name: 'NEAR Protocal',
    symbol: 'NEAR',
    unit: '18',
    contractAddress: '0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
    signature:
      '12044e4541520000001fa4a73a3f0133f0025378af00236f3abdee5d633045022071F24CF20608D25DBCC76C7279B94BC7C6F191ABD755C9BA365483A207B77D76022100E0867ECA977A867AE543AAC750CD3BD128234F2831B34A70B0E4E1450BD342F5',
  },
  // Binance-Peg BUSD
  {
    name: 'Binance-Peg BUSD',
    symbol: 'BUSD',
    unit: '18',
    contractAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    signature:
      '120442555344000000e9e7cea3dedca5984780bafc599bd69add087d563045022100A4DCE1BCB9C6DC3EDC8820188D043A9F34612488D503811BA75AAC964F0F640202207BA4E168368444FA049B27BB1F604580E75907A64344BBA42C44BEA0F5DF08F0',
  },
  // PancakeSwap Token
  {
    name: 'PancakeSwap Token',
    symbol: 'Cake',
    unit: '18',
    contractAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    signature:
      '120443616b650000000e09fabb73bd3ade0a17ecc321fd13a19e81ce8230440220553DAE4CA56493E3C308CD6191340D768FD5410B25C7BE3E4E39230954138DF602201A6A116F3F646CE31754B77CECA398A9CD67BDE2EDF44D01C7F8B4ACBC361847',
  },
  // Tether USD(BEP - 20)
  {
    name: 'Tether USD (BEP-20)',
    symbol: 'USDT',
    unit: '18',
    contractAddress: '0x55d398326f99059ff775485246999027b3197955',
    signature:
      '12045553445400000055d398326f99059ff775485246999027b3197955304402204F3D200CBAD5D423A88B3AA2525D8EEDBB72283BB7212B1D2F3D25DF8701642E0220356D4106B4CFDC110891D69EE80E6B3A35BEF963570081ECA309D328A68F82C6',
  },
  // BakeryToken
  {
    name: 'BakeryToken',
    symbol: 'BAKE',
    unit: '18',
    contractAddress: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    signature:
      '120442414b45000000E02dF9e3e622DeBdD69fb838bB799E3F168902c530450220563AAD23D77B3B2E3DBE1DE79D96CC536D8DDAD7D4840E309B3B984394054130022100C687E78802FEF40B3F94656CC340434E28201670CE9B470E792A950B1F3F5997',
  },
  // DODO bird
  {
    name: 'DODO bird',
    symbol: 'DODO',
    unit: '18',
    contractAddress: '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
    signature:
      '1204444f444f00000067ee3cb086f8a16f34bee3ca72fad36f7db929e23046022100F239A9144979839EB4A35F4385E934C7D1DC4083440F2BFD376247EC5A9B86AA022100E8C4325D3AA917AB9D1C2FB08972904AE46D83780D4857A678867EAF3ECAA223',
  },
  // Binance-Peg Dogecoin Token
  {
    name: 'Binance-Peg Dogecoin Token',
    symbol: 'DOGE',
    unit: '8',
    contractAddress: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    signature:
      '0804444f4745000000ba2ae424d960c26247dd6c32edc70b295c744c433045022004A90A886E550B3717AD52087118090C5077DBB0E9BDC95E47FB045C276F166C022100B360A35C4271C8A9F0B4D7E578BC547ED79518A310FB8EB217731E722CA7DEDE',
  },
  // Binance-Peg Axie Infinity Shard Token
  {
    name: 'Binance-Peg Axie Infinity Shard Token',
    symbol: 'AXS',
    unit: '18',
    contractAddress: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
    signature:
      '120341585300000000715d400f88c167884bbcc41c5fea407ed4d2f8a0304402201762CE742FF7CE93375585A7C40372F81D97A909B13E470DB81BB7B80EE27429022046C445E856CF26AD4F9F4FFEB34629A501C0653D62F0182F5566A3C4E0747A45',
  },
  // Binance-Peg USD Coin
  {
    name: 'Binance-Peg USD Coin',
    symbol: 'USDC',
    unit: '18',
    contractAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    signature:
      '1204555344430000008ac76a51cc950d9822d68b83fe1ad97b32cd580d3045022100C057B3A6CBFEAE122049FCBA1B2B7A9FF0071E940CB2C8BB21F0E0B3A0A3AC99022016B84B845B11054261B3321155B7EE5181EA9D91485685D88CF2BB84BBB4974A',
  },
  // Binance-Peg Ethereum Classic
  {
    name: 'Binance-Peg Ethereum Classic',
    symbol: 'ETC',
    unit: '18',
    contractAddress: '0x3d6545b08693dae087e957cb1180ee38b9e3c25e',
    signature:
      '1203455443000000003d6545b08693dae087e957cb1180ee38b9e3c25e304502200FF335E7232C09C3A81746301F8A92639F90DCF7CF337F7B644A0884A14003B5022100D957DDAA383F923F2F2D7767B79D9581AEA08B7E40EFE5D7F4376F52F0ED2CA8',
  },
  // Binance-Peg EOS Token
  {
    name: 'Binance-Peg EOS Token',
    symbol: 'EOS',
    unit: '18',
    contractAddress: '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6',
    signature:
      '1203454f530000000056b6fb708fc5732dec1afc8d8556423a2edccbd63046022100E7B66168D4C4696D5B0856FFACC70B33BA4FD9C66249AC7009EB6C67F80CF139022100AF75B7A83F71D3CD82755BBC29EA8D064E65B1ACAA5B09A652FF153213666C6E',
  },
  // Binance-Peg Filecoin
  {
    name: 'Binance-Peg Filecoin',
    symbol: 'FIL',
    unit: '18',
    contractAddress: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
    signature:
      '120346494c000000000d8ce2a99bb6e3b7db580ed848240e4a0f9ae1533044022032880B39B0FD823CCB304CA1618CD09B86426DAFD0B019BBC5BDC30C7B0D7FF402202D89949F8F48CDDF9D6D717BD2277612DFC02C6AC27C3F47150E0698457C5E91',
  },
  // ORE Token
  {
    name: 'ORE Token',
    symbol: 'ORE',
    unit: '18',
    contractAddress: '0x8e2d8f40818fbaba663db6a24fb9b527fc7100be',
    signature:
      '12034f5245000000008e2d8f40818fbaba663db6a24fb9b527fc7100be304402205BF0998DF9E34B76071C9E6C83A98242727054DBB5387EA4E3A6B544B7962441022069649068FC0E59FC5467DF3BD0D8F9757970601FA97BAE4CCF2EC5FC99E004B4',
  },
  // Heropark
  {
    name: 'Heropark',
    symbol: 'HP',
    unit: '18',
    contractAddress: '0xede1f9cdB98B4cA6A804De268b0acA18DCE192e8',
    signature:
      '120248500000000000ede1f9cdB98B4cA6A804De268b0acA18DCE192e8304502204FBED8E4CA5C36BBCAA6664BD209DF0FA78E0156864E11C32A0DF3D66DD25152022100C618E4A9B9FE1EA797EC02EFEEC6A08956D2A794FCD997790E7D4C9B3A805A49',
  },
  // Mobox
  {
    name: 'Mobox',
    symbol: 'MBOX',
    unit: '18',
    contractAddress: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
    signature:
      '12044d424f580000003203c9E46cA618C8C1cE5dC67e7e9D75f5da23773044022038ED28E66010B1CD24F8A59292702F5957AF9C19B3B3B171A3F8396AF8378E93022047917885B2FCC890BE542D61E1DB91A9A2370133E1265A00DEF0B0AC3590C75B',
  },
  // BitTorrent-New
  {
    name: 'BitTorrent-New',
    symbol: 'BTT',
    unit: '18',
    contractAddress: '0x352Cb5E19b12FC216548a2677bD0fce83BaE434B',
    signature:
      '120342545400000000352Cb5E19b12FC216548a2677bD0fce83BaE434B304502200C85618A2CF6293D96186D567B9F69FED6B99E113E90E97DD775DABD63C037D9022100E719592F578A6C77C875C95E35FD9E2615C836994CB8E9018077D23C562BD464',
  },
  {
    name: 'Deeper Network',
    symbol: 'DPR',
    unit: '18',
    contractAddress: '0xA0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A4',
    signature:
      '120344505200000000A0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A43046022100F73D2E1DC837EB2C5BDB02734156E2ADD47D31E18BBD67B2DFCF7AD462CEAF3A022100F166498548F72F16B27525AD37599164014A2E30DF5148AAFD09602EEABFBD71',
  },
  {
    name: 'Binance-Peg Ethereum Token',
    symbol: 'ETH',
    unit: '18',
    contractAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    signature:
      '1203455448000000002170Ed0880ac9A755fd29B2688956BD959F933F830450220324EF13B9E9CF8877ABC92F8095866D9BCCA7FCC38F2E3B8A2272D3B0CCFC148022100F52A31DAAEE7F21FD40B5A93DBBB19C2C6FCFB973A2F0B8AA5B9E99F39579F13',
  },
  // Matic
  {
    name: 'Matic Token',
    symbol: 'MATIC',
    unit: '18',
    contractAddress: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    signature:
      '12054d415449430000CC42724C6683B7E57334c4E856f4c9965ED682bD3046022100878555E94857CF836B10C92C6D605DA55BEB05DD6CED65AF5E68B073AB467519022100FF4AEE54BC38091B32FAC12ADEB1FB9FC4A4C1B39E0F6C70E5D74594EB0464C0',
  },
];
