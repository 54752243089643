export const COIN_TYPE = '8000003c';

export const TRANSFER = {
  script: `03000601C707000000003CCC0710C0C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF6CC071094CAA02700C2A2D700FFF6CC071080CC0E1001C2E09700CC07C0028080C3709710DC07C003455448CC0FC0023078BAA02F6C0E04DDF09700DAA2D7C0FFF612D207CC05065052455353425554546F4E`,
  signature: `0030450220201C3ADEEF531C6CD6E8F082477FF048E45F39B85086C2F40BE96840CA4840F6022100C8A36252C7606D9F2D9E6F58538F967C7F6DEFEE52B536439512CB8CD9993DB0`,
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const ERC20 = {
  script: `03000601C707000000003CCC07C002F800C2ACD70034FFF8C2A5D700FFF6C2ACD7002AFFF6CC071094CAAC270047CC07C01380B844a9059cbb000000000000000000000000CAA02700CC07200000000000000000000000000000000000000000CAA2C7000CCC0E1001C2E09700CC07C0028080C37097C002DC07C00345544811ACC7CC3E1D045B1507C004CC0F104012AC17C03F0401071507C002FF00B5AC17003FCAA6BF00DEF09700250F00CC0FC0023078BAA02F6C0E04DDF0970012AC17C03E0400141507C002FF00B5AC17003EDAA2C7B00CD207CC05065052455353425554546F4E`,
  signature: `30460221009A706915A2EE0AE663ACF90D9DD59BBEEC111EB12B099E4751219DDC993A01E7022100BA25635AB68F4EF7711D8D880A0BB1A81CA899C78884ECC4183B715F8F047D69`,
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const SmartContract = {
  script: `03000601C707000000003CCC07C002F800C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C2AC97003CCC0E1001C2E09700CC07C0028080C37097C002DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  signature:
    `304602210089EC4D1DDD9EEDA00C2A10DDB1513B7CF9EE13C36067688C52CA54A681A14A650221008D15D8509DC140D3DBB81D31C01CAD1917812B5C18A613186F904BC84857BA1C`.padStart(
      144,
      '0'
    ),
  // For Ropsten Testnet
  // script: `03000601C707000000003CCC07C002F800C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C2AC97003CCC0E1003C2E09700CC07C0028080C37097C002DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  // signature:
  //   `3046022100C13DB79F06227381AE657C5326DC9169C84BAE6786143105D77381A0051C9671022100E12470A73A9FAB65767B42D07D6434BC9FCEE717A0512E7196DF0C320D63B846`.padStart(
  //     144,
  //     '0'
  //   ),
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const SmartContractSegment = {
  script: `03050601C707000000003CA00700C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C4ACC7003A04CC0E1001C2E09700CC07C0028080BE0710DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  signature:
    `30450220418A010D1F92DAD570FE7D74424BB28927A14474AB68A008440EB4B49F2B77E30221008C3E598B4BE9D33F54C8C04B40AF5136733FE497791A2E43D107EE53E4D87DD6`.padStart(
      144,
      `0`
    ),
  // For Ropsten Testnet
  // script: `03050601C707000000003CA00700C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C4ACC7003A04CC0E1003C2E09700CC07C0028080BE0710DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  // signature:
  //   `3044022053997697E12DD4641E8F7AA392D895C8C8FB734C89792955CA62C9C9EA8BFEE4022001A106EDBF531D55D000321BCB6571C80FB557AA7AE9DC91E173720F7ABD2536`.padStart(
  //     144,
  //     `0`
  //   ),
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const SIGN_MESSAGE = {
  script: `03000601C707000000003CCC07C01A19457468657265756D205369676E6564204D6573736167653A0ACAA09700DC07C003455448D207C0074D455353414745D207CC05065052455353425554546F4E`,
  signature: `0000304402200745C5665A9CE0FA0C2894E77629A33077D9AE76F23566DC804C64BF38D27FC0022076645BEEF5A522A02D272DA3D7065D1F092C5C03B024A3F1B3A19C144CF98970`,
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const SIGN_TYPED_DATA = {
  script: `03000601C707000000003CCC07C0021901CAA057005AA597C006DC07C003455448D207C006454950373132D207CC05065052455353425554546F4E`,
  signature: `3046022100D1EF6F45445A32475A22495F16716666B3C63F5A1820F25D79D0BE91C68147BA02210083248264E663A6F4FE4E319C06DEAB14CEDC76D17FD4586DEFF828E495FFF5B9`,
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const EIP1559Transfer = {
  script: `03040601C707000000003CCC071002A00700CC071001C2ACD7003CFFF8C2ACD7001EFFF6C2ACD70028FFF6C2ACD70032FFF6CC071094CAA02700C2A2D700FFF6CC071080CC0710C0BE0710DC07C003455448CC0FC0023078BAA02F6C0E04DDF09700DAA2D7C0FFF612D207CC05065052455353425554546F4E`,
  signature: `3046022100F3CA891D06B8284C01B9E51CD478E7BBA14CD99F137383F2EAD642747222E2F9022100A121B0DE524F00D063DAB9E51E86B4CAF1B1874F79570AEE1AA437DFAD750C1C`,
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const EIP1559ERC20 = {
  script: `03040601C707000000003CCC071002A00700CC071001C2ACD7003EFFF8C2A5D700FFF6C2ACD7002AFFF6C2ACD70034FFF6CC071094CAAC27004FCC07C01380B844a9059cbb000000000000000000000000CAA02700CC07200000000000000000000000000000000000000000CAA2C7000CCC0710C0BE0710DC07C00345544811ACC7CC461D04631507C004CC0F104012AC17C0470401071507C002FF00B5AC170047CAACBF0048DEF09700250F00CC0FC0023078BAA02F6C0E04DDF0970012AC17C0460400141507C002FF00B5AC170046DAA2C7B00CD207CC05065052455353425554546F4E`,
  signature: `00304502207A63FB17CEA7E123C1BF12CBE3687614FCD677DE13171CC0B275E9659421762A022100ED2A5EC6AB2736C1D9087033CB48181784859A10C8A9674ADEFE34A84D520646`,
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const EIP1559SmartContract = {
  script: `03040601C707000000003CCC071002A00700CC071001C2ACD7003CFFF8C2ACD7001EFFF6C2ACD70028FFF6C2ACD70032FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C2AC970044CC0710C0BE0710DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  signature:
    `30450221008E37E8BADC645B749B0608A8DB0A9577798582F96BAEC5EC53694485A9DFFDB7022011A2A6D060577CC185C94AD17AF441AB0F5BCAE301A9F0E7611B0FF8614237C1`.padStart(
      144,
      '0'
    ),
  // For Ropsten Testnet
  // script: `03040601C707000000003CCC071002A00700CC071003C2ACD7003CFFF8C2ACD7001EFFF6C2ACD70028FFF6C2ACD70032FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C2AC970044CC0710C0BE0710DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  // signature:
  //   `3046022100B1AC9B0C839F1492FF311FCA6AB9DE6D8A9A16EAFF629661D78804C25239A9C9022100821E3540160C5BF89DED14569F1985F19791AABCB05DBE9C0236453F4C6B2037`.padStart(
  //     144,
  //     '0'
  //   ),
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};

export const EIP1559SmartContractSegment = {
  script: `03050601C707000000003CCC071002A00700CC071001C2ACD7003CFFF8C2ACD7001EFFF6C2ACD70028FFF6C2ACD70032FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C4ACC7004404CC0710C0BE0710DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  signature:
    `304402204FB15EF6265448A62E72CDB21FA585F97D05DE852AFC08CC792FCE1C3CAA472802204DD8B51801089F1D510222664D6D7A9D291671FAC8480458EA5D19E5B354B976`.padStart(
      144,
      `0`
    ),
  // For Ropsten Testnet
  // script: `03050601C707000000003CCC071002A00700CC071003C2ACD7003CFFF8C2ACD7001EFFF6C2ACD70028FFF6C2ACD70032FFF61AA027C0080000000000000000000000000000000000000000CC0710801507C008CC071094CAA02700C2A2D700FFF6C4ACC7004404CC0710C0BE0710DC07C003455448D207C005534D415254D207CC05065052455353425554546F4E`,
  // signature:
  //   `304502201BFF608900A33B23558121437434B7B84AE1AC48ECC150237EA55AF75A829A5A022100BF0FC8A3B7344C3A369B6A9FB714088B5D4D05E2736C3D0C5B39E5EA864671B0`.padStart(
  //     144,
  //     `0`
  //   ),
  get scriptWithSignature(): string {
    return this.script + this.signature;
  },
};
