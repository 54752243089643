export const TOKENTYPE = [
  //SHIB
  {
    name: 'SHIBA INU',
    symbol: 'SHIB',
    unit: '18',
    contractAddress: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    signature: `12045348494200000095aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE304402203AB47B8CB892AAE1205115CC02B0146FC25C9ABA864E5900AB5C6E2BFABF4CB402207F8A92A765FA853FA758C246CE157D824EB01A849F0F05EFDF21A92505F0181F`,
  },
  //VLX
  {
    name: 'Velas',
    symbol: 'VLX',
    unit: '18',
    contractAddress: '0x8c543aed163909142695f2d2acd0d55791a9edb9',
    signature: `1203564c58000000008c543aed163909142695f2d2acd0d55791a9edb93045022060708C33F05330D8FA5580FE3A8D8484727126CACBC44078C162C83899E6586C022100B3B0AF84F42CF2D87CA78E98E15D43E787EC30E9E6DA61168AB2F1319FCA5BB7`,
  },
  // MANA
  {
    name: 'Decentraland MANA',
    symbol: 'MANA',
    unit: '18',
    contractAddress: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    signature: `12044d414e410000000f5d2fb29fb7d3cfee444a200298f468908cc94230460221009BE587E89C97AE5D025242EC86758B7729FB8A786B29402BBFE2F9603C543640022100F13F68EF14F3EC7F1CD1D0807C1C294F738110B8D0C081DFDA44E436F50D29BD`,
  },
  //AXS
  {
    name: 'Axie Infinity Shard',
    symbol: 'AXS',
    unit: '18',
    contractAddress: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
    signature: `120341585300000000bb0e17ef65f82ab018d8edd776e8dd940327b28b3046022100A1E71061C2C1B1A145DA6522F85152BA4465ABFEB3C93B12AA8081709AAFE09902210089BF00B904BE125DA7BEA76C591C0AE28DB08E9D5473AB05D35965886D56596F`,
  },
  //FTT
  {
    name: 'FTX Token',
    symbol: 'FTT',
    unit: '18',
    contractAddress: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9',
    signature: `12034654540000000050d1c9771902476076ecfc8b2a83ad6b9355a4c930440220085F45EC6F391E0F756674E4553D651B4B463D7C6D925D4F3077A19BC333CD2C02203DFD9E50D156FEF7D21603CF74A085FA7863FDA698F6C1DF4C257FFAF4952D14`,
  },
  //Gala
  {
    name: 'Gala',
    symbol: 'GALA',
    unit: '8',
    contractAddress: '0xd1d2Eb1B1e90B638588728b4130137D262C87cae',
    signature: `080447414c41000000d1d2Eb1B1e90B638588728b4130137D262C87cae304502210081096B1954F56E78F38375211B8720167AE50F41E55D557CFAAA78B5DA916AB402207146F03383EDDF20133E0D4B6C03CF782B52CA37C6B3CE37AFA3518FFCF63D07`,
  },
  // MAX
  {
    name: 'MAX Token',
    symbol: 'MAX',
    unit: '18',
    contractAddress: '0xe7976c4Efc60d9f4C200Cc1bCEF1A1e3B02c73e7',
    signature: `12034d415800000000e7976c4Efc60d9f4C200Cc1bCEF1A1e3B02c73e730450220295DB5F8518310026DB27496552A8C8AE6A00D6FCE6509A3A5C909C8B1A8F82D022100AD4C404C3B87A6AD02041233189354F9AB2CEE14666CE823AF81438F157A93F5`,
  },
  // ACEX
  {
    name: 'ACEX',
    symbol: 'ACEX',
    unit: '8',
    contractAddress: '0x0be7c99ff48fccb4aa8096d174cacf079c3d1717',
    signature: `0804414345580000000be7c99ff48fccb4aa8096d174cacf079c3d17173045022100A9A8157BED8DDDBDCABF2E17401B1F63EB5FBB106446D61FC41F92180D87C164022069A1F096ABABC2226C771EAFA4E375B12160DD79842C6556CCBED51A15AC904D`,
  },
  // BCNT
  {
    name: 'Bincentive Token',
    symbol: 'BCNT',
    unit: '18',
    contractAddress: '0x9669890e48f330acd88b78d63e1a6b3482652cd9',
    signature: `120442434e540000009669890e48f330acd88b78d63e1a6b3482652cd93045022100BF28D1E6470BF1D336B829685C3FF7639BE2A83334BA2EAAD0CD1F1954FC1F6A02206B9C2EEFAD05815358683AEA9B7EFBEF0490774313E26CB89189FE035C0BC8D0`,
  },
  // HAKKA
  {
    name: 'Hakka Finance',
    symbol: 'HAKKA',
    unit: '18',
    contractAddress: '0x0E29e5AbbB5FD88e28b2d355774e73BD47dE3bcd',
    signature: `120548414b4b4100000E29e5AbbB5FD88e28b2d355774e73BD47dE3bcd3045022045D28EBA843E4A7AFC9A0BED6F7F4C6ADFA265E7B17B1D2460DB3F9DF587698F022100D5B24A47E0CE03AB621661411ED9EB5FA8FA18A01927DD1CB242B3B66D131F53`,
  },
  // WOO
  {
    name: 'Wootrade Network',
    symbol: 'WOO',
    unit: '18',
    contractAddress: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
    signature: `1203574f4f000000004691937a7508860f876c9c0a2a617e7d9e945d4b3045022036626B00EBFF247B1950932A612F2578214BC2CDB084CE0A37516B96375D2BD9022100DA647C4D51C7D01EFEFCDD4A93A7D683F25A67250B848D763FD1F7BE9C7DB2E6`,
  },
  // BITO
  {
    name: 'BitoPro Coin',
    symbol: 'BITO',
    unit: '18',
    contractAddress: '0x93b1e78a3e652cd2e71c4a767595b77282344932',
    signature: `12044249544f00000093b1e78a3e652cd2e71c4a767595b772823449323045022100F672CE8016F6A13BF18C617369A12AC6A8583457D35F8494EBE3B3F3B4EDB77602202EC6A482E50E760EE8A205BB92E0E2989B72AF19291C6546A2ACF1F853867430`,
  },
  // CREAM
  {
    name: 'Cream',
    symbol: 'CREAM',
    unit: '18',
    contractAddress: '0x2ba592F78dB6436527729929AAf6c908497cB200',
    signature: `1205435245414d00002ba592F78dB6436527729929AAf6c908497cB200304502210099D71852A0D90ADE36C3A584356613BD569E7D7628D160DDE3EAD9202006F77D02201714F8A6B0CBD859D0231DD65B4597F7824E83F23BD4C0F479C02CAF7AA9AEDC`,
  },
  // Finiko
  {
    name: 'Finiko',
    symbol: 'FNK',
    unit: '18',
    contractAddress: '0xb5fe099475d3030dde498c3bb6f3854f762a48ad',
    signature: `1203464e4b00000000b5fe099475d3030dde498c3bb6f3854f762a48ad3046022100E8BE253BB425A0EEC7F13A8C1B8FD16792506E084CDF77A97B7E3EAE96DC73290221008A3B3CB23BBCFBCF9B06EB8AC383B10F167E64F203457471284FE99746EACD86`,
  },
  // Bavala
  {
    name: 'Bavala',
    symbol: 'BVA',
    unit: '18',
    contractAddress: '0x10d88d7495ca381df1391229bdb82d015b9ad17d',
    signature: `12034256410000000010d88d7495ca381df1391229bdb82d015b9ad17d3045022100F79A32B9BCA9CC4617F7B44BD54121ECA033929275CA44E936AA13FE8538CFA002206764327F9391794DB042776A6469DA6BE63701247FE0151D40B54DF49C691614`,
  },
  // ChainLink Token
  {
    name: 'ChainLink Token',
    symbol: 'LINK',
    unit: '18',
    contractAddress: '0x514910771af9ca656af840dff83e8264ecf986ca',
    signature: `12044c494e4b000000514910771af9ca656af840dff83e8264ecf986ca304402204D9E99D72A06EEC4EB39991D5AF7DC1FB068575774A159BB648A381EC80E7D3902207456F37B36A85B45DAD42D1D5407533137A80204A3A99B1B198D4D2F87E8ECA1`,
  },
  // Bitfinex LEO Token
  {
    name: 'Bitfinex LEO Token',
    symbol: 'LEO',
    unit: '18',
    contractAddress: '0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3',
    signature: `12034c454f000000002af5d2ad76741191d15dfe7bf6ac92d4bd912ca33046022100ED6AFE0D0B9A36B20625139562E245EED7C24164BA7599F7CAA029E1C61100E7022100B5A52CD3E6F9E2FC982D69604FADD9A0D62AC1A030766051005F7C63D91A771E`,
  },
  // HuobiToken
  {
    name: 'HuobiToken',
    symbol: 'HT',
    unit: '18',
    contractAddress: '0x6f259637dcd74c767781e37bc6133cd6a68aa161',
    signature: `1202485400000000006f259637dcd74c767781e37bc6133cd6a68aa16130450221009354B6FCC7D695CCF7F9514BF2D2119C123268E6FD87A480EB41D8EAEEDCDBEE02204809ADD90755EF3F4AF3EE84AC3B508DB5757B2914A81C8BFD0446CC79A54277`,
  },
  // BitSonic token
  {
    name: 'Bitsonic Coin',
    symbol: 'BSC',
    unit: '18',
    contractAddress: '0xe541504417670fb76b612b41b4392d967a1956c7',
    signature: `120342534300000000e541504417670fb76b612b41b4392d967a1956c73045022002697F909D5DA046F07682F8457F1D12F4486C4852A7F705A181E455A5A7FE9702210094AA4DF27A36ADB3B130715965158F9838A4219144FF9534C7927A7FA5750F08`,
  },

  // BOA
  {
    name: 'BOSAGORA',
    symbol: 'BOA',
    unit: '7',
    contractAddress: '0x746dda2ea243400d5a63e0700f190ab79f06489e',
    signature: `0703424f4100000000746dda2ea243400d5a63e0700f190ab79f06489e30460221009594E07F3CA5D3F09C49C267A83928DF80B9FDCB286EED72D3A84312259C9BBD022100D9CAF5C923A30F76AECC755CCAA903BB27C1FBDE35A281C68AF103C410FA34AB`,
  },

  // OKB
  {
    name: 'OKB',
    symbol: 'OKB',
    unit: '18',
    contractAddress: '0x75231f58b43240c9718dd58b4967c5114342a86c',
    signature: `12034f4b420000000075231f58b43240c9718dd58b4967c5114342a86c304402207E4F02EB2D1AD5851B7E8655C78E96325C2B43C4C92B3F65D6C9D0C76163B36B02202DD4F227967098F38145572361F45C35580E7A3459E464D539144D91DDBB42EC`,
  },

  // GGS
  {
    name: 'GGS',
    symbol: 'GGS',
    unit: '18',
    contractAddress: '0x3AED42AdeCeC95e4CB84cd8F27d11e13c7CE0c13',
    signature: `1203474753000000003AED42AdeCeC95e4CB84cd8F27d11e13c7CE0c1330450220473075967EB3683BDD6216642535F57CAD44EF47FFE5F601C0F36C90C5E9FE0D022100E20B9E1C37DA19A9050FE922F21FF149DAFAF456498A33D745BEF8DD43864FC8`,
  },

  // ZMTK
  {
    name: 'ZMTK Token',
    symbol: 'ZMTK',
    unit: '18',
    contractAddress: '0x68ce5357c20af3490fe2b787b8dddaf1050df66a',
    signature: `12045a4d544b00000068ce5357c20af3490fe2b787b8dddaf1050df66a3046022100C4D2AAA00EBA9975B695271FD20D7FAA4781DD082BF28B7E4F9B1322597EEFA70221008A9BD02499A0DB722D09237D55C03BE23826E402627E92232B4A94D05F6EB701`,
  },

  // GTST
  {
    name: 'GoldTiger Smart Token',
    symbol: 'GTST',
    unit: '4',
    contractAddress: '0xcd5f749358014282d39dc62bff5a93b3ec30f54b',
    signature: `040447545354000000cd5f749358014282d39dc62bff5a93b3ec30f54b30460221009E3F90EDC2627BA78507A6F44792BA805F40CFEBEC08A0D09BD0F3164539A9D1022100B5E2884B6B6A5DAF0C80C377E30DCB8C323DD18E960B538226CC7F52A4B9D60D`,
  },

  // WENI
  {
    name: 'WENI',
    symbol: 'WENI',
    unit: '8',
    contractAddress: '0x68e54af74b22acaccffa04ccaad13be16ed14eac',
    signature: `080457454e4900000068e54af74b22acaccffa04ccaad13be16ed14eac304402207CD36CA9E4E314322D7C977A921E44BE9083E3E39329C9583E90C9EB5AED9D5602201ADB4B855F1D8E58BEE9C6941E4FEE63767EA86B08D68BFDB61643D9D640CAA8`,
  },

  // CWTT
  {
    name: 'CoinWallet Token',
    symbol: 'CWTT',
    unit: '18',
    contractAddress: '0xbcf976c8aabdcb5bfa8da2788f2cd241021edc70',
    signature: `120443575454000000bcf976c8aabdcb5bfa8da2788f2cd241021edc70304402202705220D2BCD190B66DCD61C67C733C36E8B3BEBA1650C138FEC82601AE11C42022049619692295C37F97304CE5F3F661C572D7EA406EDF5C8F276041B3CCE7D0AA5`,
  },

  // MITH
  {
    name: 'Mithril Token',
    symbol: 'MITH',
    unit: '18',
    contractAddress: '0x3893b9422cd5d70a81edeffe3d5a1c6a978310bb',
    signature: `12044d4954480000003893b9422cd5d70a81edeffe3d5a1c6a978310bb304402202A4A49D2C46D8AB2F61CA6E15EC70F35239B4845255A23771E776D7F218F09980220326FE031BF30B36551E5C1C0835B4496AE8CEEB9EC1849D093CF7C6F4C03BE4E`,
  },

  // XYO
  {
    name: 'XY Oracle',
    symbol: 'XYO',
    unit: '18',
    contractAddress: '0x55296f69f40ea6d20e478533c15a6b08b654e758',
    signature: `120358594f0000000055296f69f40ea6d20e478533c15a6b08b654e758304402200EF5F82DAD6903520F5DD2ECBD70C11ED05136DCAB4304A781DE160884EA632E022052B0C2BAD06121DE3FFA91FE91C35BA1664E50B3C841E0F77809A29801AEBAF1`,
  },

  // GUSD
  {
    name: 'Gemini dollar',
    symbol: 'GUSD',
    unit: '2',
    contractAddress: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
    signature: `020447555344000000056fd409e1d7a124bd7017459dfea2f387b6d5cd3045022100F8265C014E82DAB7F6667AA707B4B3250DC00E83992C4B0D81CCD6632A646B020220021981C005377101225076114E628AA003EE7C7454514EB2669634BEDE0C7546`,
  },

  // ZRX
  {
    name: '0x Protocol Token',
    symbol: 'ZRX',
    unit: '18',
    contractAddress: '0xe41d2489571d322189246dafa5ebde1f4699f498',
    signature: `12035a525800000000e41d2489571d322189246dafa5ebde1f4699f498304402207AB6B570D1E93B89551E0C2C9AA083002AF4E7562980701751B458EC9A8DE10202206CC2AB4C660687F5F6B440821C12485216B385C49E3C7DB5E9D00D3AA9753B48`,
  },

  // SAI
  {
    name: 'Sai Stablecoin v1.0',
    symbol: 'SAI',
    unit: '18',
    contractAddress: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
    signature: `12035341490000000089d24a6b4ccb1b6faa2625fe562bdd9a23260359304402203D5F4768B423397EA68F6D2E493E69F1F0FCD5F7232198A9C0D685043CCC68FC0220078585F7A640FB520401D70256A938D8E771EA4941C5BD2531971BBE22842E3B`,
  },
  // DAI
  {
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    unit: '18',
    contractAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
    signature: `1203444149000000006b175474e89094c44da98b954eedeac495271d0f304402202DA4EC890EACC1A2667766EB2D63AC7313BAA5C3738C6D1AE7296FA68603552002204A8FDC63CFF04501BF5B78E84B2636306748C5F2602B4E7E0405AFEADEBEF585`,
  },
  // TrueUSD
  {
    name: 'TrueUSD',
    symbol: 'TUSD',
    unit: '18',
    contractAddress: '0x0000000000085d4780B73119b644AE5ecd22b376',
    signature: `1204545553440000000000000000085d4780B73119b644AE5ecd22b37630440220621BE12387080E9245FB41C8164BF910ECE64DA0C967F828C221B53B09660704022067D1E5E22D68179057E5A41761E5F564B72DCD5163BCB17BCC37889611A19CE9`,
  },

  // MTL
  {
    name: 'Metal',
    symbol: 'MTL',
    unit: '8',
    contractAddress: '0xF433089366899D83a9f26A773D59ec7eCF30355e',
    signature: `08034d544c00000000F433089366899D83a9f26A773D59ec7eCF30355e3045022100D79811F2BE89DE2196EDFBC5B0F8D94DA6AD6A4FB211E37B88A28B5A4ADDD5EF022078BACCDF1145B4D7B6EDA45DD9B5C31A4CB1D87BAD6176DF35C7D93EFC4507AB`,
  },

  // ATUM
  {
    name: 'Atum Token',
    symbol: 'ATU',
    unit: '18',
    contractAddress: '0xf651da6419c6a1a4b09f843ecaa7506a4db3f2c9',
    signature: `120341545500000000F651Da6419C6a1A4B09F843EcaA7506a4DB3f2c930440220590EBE88AE9313B374A53FE6FA62BFDF3F81F3C80FE260ADBFFCA49D54096D51022002F98AF31EB453D04C006B20B6A4C1C55EE4BAFAD96AA774BB78004293FD3EC7`,
  },

  // Bit Degree
  {
    name: 'BitDegree Token ',
    symbol: 'BDG',
    unit: '18',
    contractAddress: '0x1961b3331969ed52770751fc718ef530838b6dee',
    signature: `1203424447000000001961b3331969ed52770751fc718ef530838b6dee3046022100E4CDE55F48F42EBBAC08DA07231EB573F4982E4EB7FD349D36BC5B7B10BD2BDB022100EF1ABCCEB9DF49339A1C384063B7525ACEA519B47A4626A806608ED90B9C58B9`,
  },

  // Formosa Financial Token
  {
    name: 'Formosa Financial Token',
    symbol: 'FMF',
    unit: '18',
    contractAddress: '0xb4d0fdfc8497aef97d3c2892ae682ee06064a2bc',
    signature: `1203464d4600000000b4d0fdfc8497aef97d3c2892ae682ee06064a2bc304402205D923723D5ED5AA9E9867E34443B3990A44072061CDCA86F33BB5520276D78FE022053F94EF4156CE92AFA9B496F7D3B1AFDFB7C8C79BCB4E190190B753323AD12EA`,
  },

  // USD Coin
  {
    name: 'USD Coin',
    symbol: 'USDC',
    unit: '6',
    contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    signature: `060455534443000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb4830440220346A1491B50D810CFD9B1E47402CE81D33CC4768D7A3983F1874D02A2C9ED58602202601D043EB3EE0D51EA68995E2C775602152456C58F54545F59E85956257B60C`,
  },

  // Cortex Coin
  {
    name: 'Cortex Coin',
    symbol: 'CTXC',
    unit: '18',
    contractAddress: '0xea11755ae41d889ceec39a63e6ff75a02bc1c00d',
    signature: `120443545843000000ea11755ae41d889ceec39a63e6ff75a02bc1c00d30440220308CF39CD967F40F498568E5611739BE754798F3FD5A13406B888BA21D3ECB97022027F0B4D814002023324E91BE17D14A4F42BA1E482B23CCC17CCF99291D67F9A1`,
  },

  // BNB
  {
    name: 'BNB',
    symbol: 'BNB',
    unit: '18',
    contractAddress: '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
    signature: `1203424e4200000000B8c77482e45F1F44dE1745F52C74426C631bDD5230460221009EFAE83710562865B851D475F0C6CC0E6C7AE591BDAAA4C2B012E66F5CC2598B022100D76D6E6CBFEA76356141933F9BA2063FB3E74323D6A566087346CF7090083E2E`,
  },

  // ZPER
  {
    name: 'ZperToken',
    symbol: 'ZPR',
    unit: '18',
    contractAddress: '0xb5b8f5616fe42d5ceca3e87f3fddbdd8f496d760',
    signature: `12035a505200000000b5b8f5616fe42d5ceca3e87f3fddbdd8f496d7603045022100FCEE8CBDB2D873261AE90F1DCFF9280EC937D243825BE98B88545ADB3722A16E02207EA49E856042AFCFA5DA9C7604B615B059AA97EA23DFBBDA589FA7F5266914E9`,
  },

  // EOS
  {
    name: 'EOS',
    symbol: 'EOS',
    unit: '18',
    contractAddress: '0x86fa049857e0209aa7d9e616f7eb3b3b78ecfdb0',
    signature: `1203454f530000000086fa049857e0209aa7d9e616f7eb3b3b78ecfdb03045022100b99a74191815f3c54d4556511e7a0b6b0fa20d79bd58ad13bc373b9f08cde50b02202fdbbb7d43b3c01531c191c9cb33274ba34b58245fddf5cae83cdb95835628f4`,
  },

  // JOYSO
  {
    name: 'JOYSO',
    symbol: 'JOY',
    unit: '6',
    contractAddress: '0xdde12a12a6f67156e0da672be05c374e1b0a3e57',
    signature: `06034a4f5900000000DDe12a12A6f67156e0DA672be05c374e1B0a3e573045022100E5DC4AD6E4678FB6F174B987F5079417B29B6A4A12F9DE62438C959FB4E5317202207CC65AA738267D17ECE26385C77EF6AFD582F55E98D1366DBB709C944D7EF6EE`,
  },

  // USDT
  {
    name: 'Tether USD',
    symbol: 'USDT',
    unit: '6',
    contractAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    signature: `060455534454000000dac17f958d2ee523a2206206994597c13d831ec73045022009DE62567071D16F3104448C3553B908BEB9C6806F6C1C38C801EE7FDBD1523F022100E2CA0CAF5F59F64CDF91C2AD3B7AAAF3490224C41810AED666DE59623FB325B2`,
  },

  // WETH
  {
    name: 'Wrapped Ether',
    symbol: 'WETH',
    unit: '18',
    contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    signature: `120457455448000000c02aaa39b223fe8d0a0e5c4f27ead9083c756cc2304402206FC94EF6A212E25CD315DFFBB9B37E471A39BA76673525BB52B68F044CA56CC202202D61D1C70115BBD1DEC29EBF58CD12A5B702F1E823DE83E6DFBFD33C2B3745B5`,
  },

  // Old WETH
  {
    name: 'Wrapped Ether Old',
    symbol: 'WETH',
    unit: '18',
    contractAddress: '0x2956356cd2a2bf3202f771f50d3d14a367b48070',
    signature: `1204574554480000002956356cd2a2bf3202f771f50d3d14a367b48070304502200B11341FF45EAAD5353230E10BBAD3BA5374ED950DE4960A24922D1FCF32075D022100A9DE09EE45FDCE005E9E2C552E7206586B8CF312A0D8F656796AE244F690168A`,
  },

  // JAYZ
  {
    name: 'JAYZ token',
    symbol: 'JAYZ',
    unit: '5',
    contractAddress: '0xf7bd275f39802e7d4a9604547b6548150b63e440',
    signature: `05044a41595a000000f7bd275f39802e7d4a9604547b6548150b63e440304502200C8F6A2BA1D6C2710D2A799D3706F4F560464D050EF28CEF099C830F9A714DE9022100A6C230D57A8A0EA6FD60342B6010379B2D7D65061DA97CBED03272E6A606369E`,
  },
  {
    name: 'Uniswap',
    symbol: 'UNI',
    unit: '18',
    contractAddress: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    signature: `1203554e49000000001f9840a85d5af5bf1d1762f925bdaddc4201f9843045022032A25EE04E879B466179AA96A622D7CDB79DD3DB34F0552E7C4791CEC61456DB022100CDEBCECE6E6D5A40416AD9CCB964EA7AB6EA40A176F07E6055CB89A54F74BCC0`,
  },
  {
    name: 'Binance USD',
    symbol: 'BUSD',
    unit: '18',
    contractAddress: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    signature: `1204425553440000004fabb145d64652a948d72533023f6e7a623c7c533046022100977F8A017BD2208C0B56D3E66C8841A069E302EADC607DCF77BDDD4AED255677022100CBF103BC359223CF490BBD4DD4E159419A896A5173625973B57210243148B01D`,
  },
  {
    name: 'HEX',
    symbol: 'HEX',
    unit: '8',
    contractAddress: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    signature: `0803484558000000002b591e99afe9f32eaa6214f7b7629768c40eeb39304402207AB8FF116D26917A2FD6438B37A68FF51BC358D3BFC9C6770F7B59C5D6B14EF702207F1AC1EB709076A8AC7249E61E78740B13DBCD238AC4E7F7FA3F8CAC2A0330F5`,
  },
  {
    name: 'VeChain',
    symbol: 'VEN',
    unit: '18',
    contractAddress: '0xd850942ef8811f2a866692a623011bde52a462c1',
    signature: `120356454e00000000d850942ef8811f2a866692a623011bde52a462c1304502207A1C20BBF01C3F4B6DBAC52FCB1C63938A5DC5C440277356CB4BF48BCB704F43022100AC166D2B22F4C90959D19A4EB9F4CFCB002F0AAE8E092572C5A17C1EF438B74F`,
  },
  {
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    unit: '8',
    contractAddress: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    signature: `0804574254430000002260fac5e5542a773aa44fbcfedf7c193bc2c5993045022100D7996D6ABB5DFD6297F603A6984AEF4FF958BE68B3835AB4436053178982A61002207F5897EB026B6CFED9D5D80B743CE4F787781365F0122CFA9440A61BB8A01486`,
  },
  {
    name: 'Theta Token',
    symbol: 'THETA',
    unit: '18',
    contractAddress: '0x3883f5e181fccaf8410fa61e12b59bad963fb645',
    signature: `1205544845544100003883f5e181fccaf8410fa61e12b59bad963fb645304502206D2DD5471EDFA5D958B3CE1E5BC0CF704FE97C2852B9E696348E9157883F64E5022100C335B7A3E81B7096CBC3D70DA9446D99105F4B48EA80074B63F10832008795DF`,
  },
  {
    name: 'Wrapped Filecoin ',
    symbol: 'WFIL',
    unit: '18',
    contractAddress: '0x6e1A19F235bE7ED8E3369eF73b196C07257494DE',
    signature: `12045746494c0000006e1A19F235bE7ED8E3369eF73b196C07257494DE304402204361DF800188C667AB9887557EA71886F3129A03907E251D01417BE3E1E5550C02205A97DE9311AB8BCB1E46BBBD2813583DE1151776C3A1CB720F342C2E1F8A8E9C`,
  },
  {
    name: 'Compound Dai',
    symbol: 'cDAI',
    unit: '8',
    contractAddress: '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
    signature: `0804634441490000005d3a536E4D6DbD6114cc1Ead35777bAB948E36433046022100E38EA4B63D5C6727972C4CCA51D35C5B203B3727B092171E43575B8B8C7C3BBD022100A72E9128BE5AB9657D105DFFC85D92E40F151FB79A44CD9FDDFABCB27541437D`,
  },
  {
    name: 'Compound Ether',
    symbol: 'cETH',
    unit: '8',
    contractAddress: '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
    signature: `0804634554480000004ddc2d193948926d02f9b1fe9e1daa0718270ed53045022100E58E99F1DF6F6723612CC8E984E5F6D3C020C7B0104AD64844FEE1B073792FA102205B4FD1B5439D8323D48FBA94DC35FBD7DCF1D4F0360219313D6A7D3D864D2617`,
  },
  {
    name: 'Compound USD Coin',
    symbol: 'cUSDC',
    unit: '8',
    contractAddress: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
    signature: `08056355534443000039aa39c021dfbae8fac545936693ac917d5e756330440220110BCA54F8ADDB4D0A1DFA85AFB8E442299A68F490A4BA49E26E0B0D2A24E504022035D13F2E1363BCDD80B033402B53C4A07F88BD1992A055CAFD1AE87A85134507`,
  },
  {
    name: 'Celsius',
    symbol: 'CEL',
    unit: '4',
    contractAddress: '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d',
    signature: `040343454c00000000aaaebe6fe48e54f431b0c390cfaf0b017d09d42d3045022100A02D672DD398BBD3C5900E0F529295C2F21C873E9E328DA1BBC674EC12727F5502204B778A31AC4119C78A006A6BD72713C37C289C1E0628866D06FBBE649FCD9B26`,
  },
  {
    name: 'Crypto.com Coin',
    symbol: 'CRO',
    unit: '8',
    contractAddress: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
    signature: `080343524f00000000a0b73e1ff0b80914ab6fe0444e65848c4c34450b3046022100D749E41C0D914320C356638DBBB77F22060AF2DB5D9C07ED2DF3ED4C6A816F87022100F767006CE4890FBF5C3CD93E484E874D02F23086E189D26C00AD9348DEE631F4`,
  },
  {
    name: 'Maker',
    symbol: 'MKR',
    unit: '18',
    contractAddress: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    signature: `12034d4b52000000009f8f72aa9304c8b593d555f12ef6589cc3a579a23045022100B03C45DD15DB8DD3FE20BCD4CA79A560D93197427F63C9D77D468C621C06C97C0220762A441405D9773D2DDE8F868408CA2222748B2A7793B7959B52E45693243825`,
  },
  {
    name: 'BitTorrent',
    symbol: 'BTT',
    unit: '6',
    contractAddress: '0xe83cccfabd4ed148903bf36d4283ee7c8b3494d1',
    signature: `060342545400000000e83cccfabd4ed148903bf36d4283ee7c8b3494d1304502210084E5581919C49F53CD450D024B3F24DDA77B6602DD0CBE3EECF1796116DBD34402206B879E10749112328E1C7B78238B8E668989214EB5C26AC7368AAEB6E0FB79C5`,
  },
  {
    name: 'Compound',
    symbol: 'COMP',
    unit: '18',
    contractAddress: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    signature: `1204434f4d50000000c00e94cb662c3520282e6f5717214004a7f26888304502210093734C07E7F2CA7063259290411D904B7EE199B4D6747C670BA6412255EA4675022009D1FBFBFFEE08B9893EC58C9E4F68BC887CA99605B28E8D53DB77C9FCE1081C`,
  },
  {
    name: 'Wrapped UST Token',
    symbol: 'UST',
    unit: '18',
    contractAddress: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    signature: `120355535400000000a47c8bf37f92abed4a126bda807a7b7498661acd3044022015954519F6104C1CC4A632ED1F0797C319A938EB99DA5FF52CCCB67111B77DD60220018A5E417EDD6F546BAF2B5D08C717EDBA7065EF3BDCBE1B66437192D2A6052C`,
  },
  {
    name: 'SushiToken',
    symbol: 'SUSHI',
    unit: '18',
    contractAddress: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    signature: `1205535553484900006b3595068778dd592e39a122f4f5a5cf09c90fe23044022025EE8DF62C32D1FE1B18AE3A943B23371C6C5EBC5D72E765A1F3365E584D7E7D02206FD345B834BC7DA9AEF177A610E95B2383C40768E32C71BE088F36E87F413764`,
  },
  {
    name: 'Synthetix Network Token',
    symbol: 'SNX',
    unit: '18',
    contractAddress: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    signature: `1203534e5800000000c011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f3045022100D27EFE4D6A016B9A5B6770BC758185EB3A303F464B7E51E95FCD2F2A727DA0140220178D44F2E62058968FA6C07BB21C87A14A02758FBBAD6016D1AB8A160F72AEA0`,
  },
  {
    name: 'Telcoin',
    symbol: 'TEL',
    unit: '2',
    contractAddress: '0x467Bccd9d29f223BcE8043b84E8C8B282827790F',
    signature: `020354454c00000000467Bccd9d29f223BcE8043b84E8C8B282827790F3045022100F42229CA79F76AA0CE9DE7B70D0D6BF7F7A6FE5674A9D7FDAB6923C78800ED91022025104422BBD3BDF53F4CA4962940F91AE21EEEFECFA7D991F681509D54503CB7`,
  },
  {
    name: 'Amp',
    symbol: 'AMP',
    unit: '18',
    contractAddress: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
    signature: `1203414d5000000000ff20817765cb7f73d4bde2e66e067e58d11095c2304502204EEAA15B9088A7490AB7CA2A0FCFF8CC1A7A667D4A69EDC16D05E7B4AD866C8D02210088E3CBD22610A0D7D611930596A0A45876A3057F4B82FBF4F18A362D33666EBF`,
  },
  {
    name: 'HoloToken',
    symbol: 'HOT',
    unit: '18',
    contractAddress: '0x6c6ee5e31d828de241282b9606c8e98ea48526e2',
    signature: `1203484f54000000006c6ee5e31d828de241282b9606c8e98ea48526e23045022026CC1B942A08B462AF2E28B9F33B75939AB5A80C7B21ADC8548ECB9973E27CF90221008D28686123DE3B802A31F4E6DC5E24A45C68279B50D7DDD5E48CB20D76166381`,
  },
  {
    name: 'yearn.finance',
    symbol: 'YFI',
    unit: '18',
    contractAddress: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
    signature: `1203594649000000000bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e30440220303DFE0740240F15C4F880FC7F74CF1538C8D363AC6C4073B7EFCA8EE0C4587802201BA48D53D05BC320989A006558E3082B044AD48F181A807D8DEC989B56D474F0`,
  },
  {
    name: 'EnjinCoin',
    symbol: 'ENJ',
    unit: '18',
    contractAddress: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
    signature: `1203454e4a00000000f629cbd94d3791c9250152bd8dfbdf380e2a3b9c3045022100C0E7A75F4A15BC4F049F19502A7E24BF3878A1958EF766AE2ED9B31C1C37EE5302203816261D03468212206F2C52F58DCE66311287DE0AF35E07B4FEC6215A26CE93`,
  },
  {
    name: 'chiliZ',
    symbol: 'CHZ',
    unit: '18',
    contractAddress: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
    signature: `120343485a000000003506424f91fd33084466f402d5d97f05f8e3b4af3045022100E0BA77B8AE701375879D98C04063DF169D536EA59330E4867637B72BB629F0AB02202E4A7D9851EE8360C09CCADD2F97463E42F46859B2A862D9368F33A782D53F77`,
  },
  {
    name: 'Zilliqa',
    symbol: 'ZIL',
    unit: '12',
    contractAddress: '0x05f4a42e251f2d52b8ed15e9fedaacfcef1fad27',
    signature: `0c035a494c0000000005f4a42e251f2d52b8ed15e9fedaacfcef1fad2730450220765A0CE18043D2EF9420F96D0C5965B8578C2DAD654FF46B1AA8E113AFFBE72502210095C39B1F6473759C0904F5AF1FC60E6F522AE258F22CC0E0D79795F941B81591`,
  },
  {
    name: 'Paxos Standard ',
    symbol: 'PAX',
    unit: '18',
    contractAddress: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
    signature: `1203504158000000008e870d67f660d95d5be530380d0ec0bd388289e1304402202518F6FA9B70B414FF0424EA257FD38B56840642DD37777156D53FB018E8EBEA022043D6F2C386221E6547559DEEFF6BA16C1733F108FC4262F10DA4790BC6A5BDAB`,
  },
  {
    name: 'Huobi BTC ',
    symbol: 'HBTC',
    unit: '18',
    contractAddress: '0x0316EB71485b0Ab14103307bf65a021042c6d380',
    signature: `1204484254430000000316EB71485b0Ab14103307bf65a021042c6d3803044022039383EC29143B57846F3ACC8D0CF7C2BD13F9104F5AF6C4CF1ACB67B95E1B32302206A702B14309B708F8FB1B8900ADC23E79AAE7D440B486E68E2B42421DC0A3928`,
  },
  {
    name: 'Nexo',
    symbol: 'NEXO',
    unit: '18',
    contractAddress: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
    signature: `12044e45584f000000b62132e35a6c13ee1ee0f84dc5d40bad8d8152063045022100C093D42CED79F9F2976700835C810ABA2D2CF53F51715F6EDCB770166A15EF7A02202A1461C23FB21D3B0B63DBB044F0AEADDC41ED5FDE403774C0FC4A9CECF119CE`,
  },
  {
    name: 'stETH',
    symbol: 'stETH',
    unit: '18',
    contractAddress: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
    signature: `120573744554480000ae7ab96520de3a18e5e111b5eaab095312d7fe843045022049E3133909D07D533820B55272638D99C7EC54C5745B235FF72C78E0E3ABE66B0221008DFDCEDBA5D44F4A0E0A33689E2769369348A7267F1B5A946F29557DD2C41D35`,
  },
  {
    name: 'BAT',
    symbol: 'BAT',
    unit: '18',
    contractAddress: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
    signature: `1203424154000000000d8775f648430679a709e98d2b0cb6250d2887ef304502205EE71E661511AE96895F8E331F33EBB8EA71E5D725A267C8D418B0FA3FE9B2A8022100A89C6E4BC9CFEF696E9B97D1459A9CB4EBCF1D4900DDB1694AC5F2C237DE9836`,
  },
  {
    name: 'Wrapped Decentraland MANA',
    symbol: 'wMANA',
    unit: '18',
    contractAddress: '0xfd09cf7cfffa9932e33668311c4777cb9db3c9be',
    signature: `1205774d414e410000fd09cf7cfffa9932e33668311c4777cb9db3c9be304402201ADCFC9C7DCC513E300BF0D42FDDCD8C33C01C5B1D59B9C7445A642DC3B6F974022021144F9813459629CB8FEA6BCF23AA70BE42B11E4A436D7581F337DABC375615`,
  },
  {
    name: 'Fei USD',
    symbol: 'FEI',
    unit: '18',
    contractAddress: '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
    signature: `120346454900000000956F47F50A910163D8BF957Cf5846D573E7f87CA304402200933853CEE347A2B6C11E96BDF8710B2341038FCBFFA48BF42B0AEF85AFA1F2502204C0E6BE1C3D5FA6900B63F501D6C135A3E838EC867B6504329D598AF5ED48C97`,
  },
  {
    name: 'FirstBlood',
    symbol: 'ST',
    unit: '18',
    contractAddress: '0xaf30d2a7e90d7dc361c8c4585e9bb7d2f6f15bc7',
    signature: `120253540000000000af30d2a7e90d7dc361c8c4585e9bb7d2f6f15bc7304502210089E3DDAF171AAEC9AF06CF1247CD402C83D324498DD61378892D049A1479962202205533404160D49E8A31302643EBD56E9023EB3E4510F3083BD4A57923E69CB504`,
  },
  {
    name: 'HarmonyOne',
    symbol: 'ONE',
    unit: '18',
    contractAddress: '0x799a4202c12ca952cb311598a024c80ed371a41e',
    signature: `12034f4e4500000000799a4202c12ca952cb311598a024c80ed371a41e3044022070B61C1DFD0F5D975F79784894DDE84AD4666E4D4F7A12172BFA9D422C841CF90220780F7DED30575B06EC308629D6A3C5B489B4D5BF5529280EC37DB3E599038631`,
  },
  {
    name: 'Graph Token',
    symbol: 'GRT',
    unit: '18',
    contractAddress: '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
    signature: `120347525400000000c944e90c64b2c07662a292be6244bdf05cda44a73045022100B7987A789B9FF0D54DD69A5D2BA167199B9724CCAA7866452F6FE2289E4823E302201B5F9BC38A2A7C8F6351770049F46CC522C55AAA9B37C021F333562531034C19`,
  },
  {
    name: 'Bancor',
    symbol: 'BNT',
    unit: '18',
    contractAddress: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
    signature: `1203424e54000000001f573d6fb3f13d689ff844b4ce37794d79a7ff1c3045022100A361BD4EF4AA9134DFDAA4B770A7CEE93279DE2E4548184751E131107EA241F602206B4EE8F16ECB15506DEA9EA18F7797C86EA95731AF26E87FEB3BA3E782075D92`,
  },
  {
    name: 'HUSD',
    symbol: 'HUSD',
    unit: '8',
    contractAddress: '0xdf574c24545e5ffecb9a659c229253d4111d87e1',
    signature: `080448555344000000df574c24545e5ffecb9a659c229253d4111d87e13046022100D259F7283AD370682A67E06DF8E4EF7C794A6D2758F74F63BBC7C860340E346C022100AF490692DCBE13BE41D7AD46400CE68A2D6D8E6F27A306E294F257D032B223E1`,
  },
  {
    name: 'OMG Network',
    symbol: 'OMG',
    unit: '18',
    contractAddress: '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
    signature: `12034f4d4700000000d26114cd6EE289AccF82350c8d8487fedB8A0C073045022046CAD3B8FE71650FD0CEFAB65E18AA56E088A62DC94603DA3A0ABCA708C76DE8022100AB83C2CB2C5265AF6E4FEC7C741FA16EC1D97F294FF81D39C7F5DD3A1D29702B`,
  },
  {
    name: 'SushiBar',
    symbol: 'xSUSHI',
    unit: '18',
    contractAddress: '0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272',
    signature: `1206785355534849008798249c2E607446EfB7Ad49eC89dD1865Ff4272304402206EBB480396317F8DEC68BF382ADAAF6E0317DBD0136FCC501C89A1F31F660D7602201AB50236C46DFC2DE91473C5969CF9C45D84A7A16FBA286656676082370EC8B5`,
  },
  {
    name: 'UMA Voting Token v1',
    symbol: 'UMA',
    unit: '18',
    contractAddress: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
    signature: `1203554d410000000004Fa0d235C4abf4BcF4787aF4CF447DE572eF8283045022062B80652124A4349D4FE769A34653F476E5AA2A64764CDC841B2616C5ED6EB91022100A4D44BB95003F6E36183EDFC84F31E0D632A4EE7A666424884AF216411D50C33`,
  },
  {
    name: 'SwissBorg',
    symbol: 'CHSB',
    unit: '8',
    contractAddress: '0xba9d4199fab4f26efe3551d490e3821486f135ba',
    signature: `080443485342000000ba9d4199fab4f26efe3551d490e3821486f135ba304502205A51F09726A649E9885E958EA2BDB65017E61EF0E750ABA4D1AE890FD11FCAC3022100EA98A0756B861B6D9BB606AEAFF890BDDCE75584D2D1C7CD52338E22EFCBEAB2`,
  },
  {
    name: 'Fantom Token',
    symbol: 'FTM',
    unit: '18',
    contractAddress: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    signature: `120346544d000000004e15361fd6b4bb609fa63c81a2be19d8737178703044022012F3FDF5FA57C4C6A415E0B70E71F20E2341C7B01EB2934C3AD0FE8271058EA0022079225022670779FADBB9B8543FDD30772730405721EF95F0C69895E8FA9D7665`,
  },
  {
    name: 'IOSToken',
    symbol: 'IOST',
    unit: '18',
    contractAddress: '0xfa1a856cfa3409cfa145fa4e20eb270df3eb21ab',
    signature: `1204494f5354000000fa1a856cfa3409cfa145fa4e20eb270df3eb21ab3044022078A518138E634254421D5F390EA8B6BB379732777EF7092FE702B5A89DDABD0B022024DF6DB7D565D1B4CC2607C6639A539D6A1B99B1CA296304E62A091C938756D3`,
  },
  {
    name: 'XinFin XDCE',
    symbol: 'XDCE',
    unit: '18',
    contractAddress: '0x41ab1b6fcbb2fa9dced81acbdec13ea6315f2bf2',
    signature: `12045844434500000041ab1b6fcbb2fa9dced81acbdec13ea6315f2bf2304402202EB6CA9F0481D96C9968C448916EE2356BCB7A77EF74B4ABF13D17E52F84C6A002203061C8E13EDB2E6E06B51731900581802BD55E856A9E5774F2ACE28CDA9EF6ED`,
  },
  {
    name: 'NXM',
    symbol: 'NXM',
    unit: '18',
    contractAddress: '0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b',
    signature: `12034e584d00000000d7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b3045022100F24AB360F59B08ED18831192111BE06D3457627AB7238BABEF74B1CBF5CD686F02202F83FEE245B0C8F1E464FD4F1555F0149A0197D8F985652A8EDE79957055B9FD`,
  },
  {
    name: 'Aave interest bearing CRV',
    symbol: 'aCRV',
    unit: '18',
    contractAddress: '0x8dae6cb04688c62d939ed9b68d32bc62e49970b1',
    signature: `1204614352560000008dae6cb04688c62d939ed9b68d32bc62e49970b13046022100E75B1F2455E13DA747F3C291A9FD6490E803272602BD3F76DB7D9C97E698E24E022100E05424CEAADB35283E7E3E9806675A5713201420185F47142E5D84E046A0BF95`,
  },
  {
    name: 'Curve DAO Token',
    symbol: 'CRV',
    unit: '18',
    contractAddress: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    signature: `120343525600000000D533a949740bb3306d119CC777fa900bA034cd523046022100BEE87B0499FB09FADD8A3C853219B6647BB9CA0DA96A77622948E1284531212E02210083171373DBD426CDB0B7DB34142511DD9837754856F820F7CEA5158921181B46`,
  },
  {
    name: 'Compound USDT',
    symbol: 'cUSDT',
    unit: '8',
    contractAddress: '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
    signature: `080563555344540000f650c3d88d12db855b8bf7d11be6c55a4e07dcc93045022063FE6D57429F34DACCFF90F28CAF3AF777616516147BD8EF4AFBA4AE562B639C022100B9B1E52E0A784E1DD9B8AEA72F7AAAB9F066786360E75EB6A566D146EA2F42A3`,
  },
  {
    name: 'Voyager',
    symbol: 'VGX',
    unit: '8',
    contractAddress: '0x5af2be193a6abca9c8817001f45744777db30756',
    signature: `0803564758000000005af2be193a6abca9c8817001f45744777db307563046022100AFD1438AD8D02DD2C319A43B9AFECF9706E21C8560CA066945AEB0E91CE626C3022100A31407D176196AED65AA11EA4B43092270BA8F5299D44785F8F99785FC2A7A17`,
  },
  {
    name: 'KuCoin Token',
    symbol: 'KCS',
    unit: '6',
    contractAddress: '0xf34960d9d60be18cc1d5afc1a6f012a723a28811',
    signature: `06034b435300000000f34960d9d60be18cc1d5afc1a6f012a723a2881130450220034096CF76FAED7BFFAD980171CFB659FC386FDD20EAD458BE8A3CF02822A54B022100D4D0A4441EA6E432599E43CEDB8ECEB95420D156FDFD8195692CBC369BF90653`,
  },
  {
    name: 'Livepeer Token',
    symbol: 'LPT',
    unit: '18',
    contractAddress: '0x58b6a8a3302369daec383334672404ee733ab239',
    signature: `12034c50540000000058b6a8a3302369daec383334672404ee733ab239304402202ADCC0C5608C7714706F2FE90DBC99F7C9687ABBEF86646716E3BA1CA20F57A302200AB369A2521873777ED586C6583C2A6813E828FED5AAB10402AD39119B0865D6`,
  },
  {
    name: 'Quant',
    symbol: 'QNT',
    unit: '18',
    contractAddress: '0x4a220e6096b25eadb88358cb44068a3248254675',
    signature: `1203514e54000000004a220e6096b25eadb88358cb44068a32482546753044022033352B85082C99F5829EC71BCE93D91F62060EF0074D2996735509210ECF57FC02207A165C36A9154C3E7992D9ECEA6707138D911CFF76AAA5511C55687A33F4E5EE`,
  },
  {
    name: 'FEGtoken',
    symbol: 'FEG',
    unit: '9',
    contractAddress: '0x389999216860ab8e0175387a0c90e5c52522c945',
    signature: `090346454700000000389999216860ab8e0175387a0c90e5c52522c9453046022100CDBC0F46561A722F0A728EBBB37B386DD621D3B1F1BD4C02DD33427B4A3905D502210085E9B5E239AE1525E871F56C5A0FA344CEB70D62C0BFCABB93A1CB82A9720434`,
  },
  {
    name: '1INCH Token ',
    symbol: '1INCH',
    unit: '18',
    contractAddress: '0x111111111117dc0aa78b770fa6a738034120c302',
    signature: `120531494e43480000111111111117dc0aa78b770fa6a738034120c30230450221008DC469399ECCE74239615A75C353FA6F94AAA469C72336FCD238D003E0FFB09302200E37BD3C9AA7C3EDF2B6CFA8A69038696029962541760AA1663105FC7C0E64BE`,
  },
  {
    name: 'BitMax token',
    symbol: 'BTMX',
    unit: '18',
    contractAddress: '0xcca0c9c383076649604eE31b20248BC04FdF61cA',
    signature: `120442544d58000000cca0c9c383076649604eE31b20248BC04FdF61cA30450221008CBED36583921C1C517A3906E5A9D541A1CEE72E69C180D7455F5B244A8088AF02204B1CCB3A21131686505F574D6428B9540A2E44E8A1BE1D566A8ED92BA38E3AD0`,
  },
  {
    name: 'Wrapped Celo',
    symbol: 'wCELO',
    unit: '18',
    contractAddress: '0xe452e6ea2ddeb012e20db73bf5d3863a3ac8d77a',
    signature: `12057743454c4f0000e452e6ea2ddeb012e20db73bf5d3863a3ac8d77a3046022100FEA6C0C2D1E1C800C84645C1D252E017F4936B1A4DF35C5304B3B1EB46B8AF8F022100ECAFEBFD4745211E05F442621BCE875F5763FB3283C3748C6E8FE935D90EA67F`,
  },
  {
    name: 'LoopringCoin V2',
    symbol: 'LRC',
    unit: '18',
    contractAddress: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
    signature: `12034c524300000000bbbbca6a901c926f240b89eacb641d8aec7aeafd3046022100CA0E92551EB232A4ADF8A83BEE40370C1F1998785873FB1187765365112225AF022100CD4242F5D09E2CE284EE228437E84A443226E9BFBA97A4B090D36A3E35D668CA`,
  },
  {
    name: 'Pundi X Token',
    symbol: 'PUNDIX',
    unit: '18',
    contractAddress: '0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38',
    signature: `120650554e444958000fd10b9899882a6f2fcb5c371e17e70fdee00c38304502207CBE4A1002B9910E21E510E689E008C8257FB42502FF9565BD990050D3EDB45102210094CB329A8102442A414ECC6765EE73E7D5E64D23BD86570772A677B7590BA70B`,
  },
  {
    name: 'renBTC',
    symbol: 'renBTC',
    unit: '8',
    contractAddress: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    signature: `080672656e42544300eb4c2781e4eba804ce9a9803c67d0893436bb27d3046022100CB8EF5ABA958D4086F1987D3591B21ED270D9BF44C9B965FDB139946C2190F68022100DDEA0DECD92707840DE0135426B7C1337AA4B800AA83C661894490D07DCFE6DC`,
  },
  {
    name: 'StatusNetwork',
    symbol: 'SNT',
    unit: '18',
    contractAddress: '0x744d70fdbe2ba4cf95131626614a1763df805b9e',
    signature: `1203534e5400000000744d70fdbe2ba4cf95131626614a1763df805b9e3046022100A6218BB8DDC30931BA5129FD65F5F69D0EB816894C33632201BD056A04B6797A0221009A48B3416F30F0724C39EB62260617A4A8638CE8C76872E3C33AE2C62B37B9B4`,
  },
  {
    name: 'DENT',
    symbol: 'DENT',
    unit: '8',
    contractAddress: '0x3597bfd533a99c9aa083587b074434e61eb0a258',
    signature: `080444454e540000003597bfd533a99c9aa083587b074434e61eb0a25830460221009DD1D369F6B98BC678689FF44EB50BF179904FE2E66064AFFF5C94F60766822B022100D6EE3D005D856314FACA78A19A9FB42C8667386FC04172E258DEA5842F8D0803`,
  },
  {
    name: 'RLC',
    symbol: 'RLC',
    unit: '9',
    contractAddress: '0x607F4C5BB672230e8672085532f7e901544a7375',
    signature: `0903524c4300000000607F4C5BB672230e8672085532f7e901544a73753044022068B677FBE05D95189C08D13C6E670ABAB47EEB096016B316BA0BFCFA52653874022057201EDE8B14F485D51B18ED327C23BD2D1604A31D15176633C7E78A0F9F6049`,
  },
  {
    name: 'BandToken',
    symbol: 'BAND',
    unit: '18',
    contractAddress: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
    signature: `120442414e44000000ba11d00c5f74255f56a5e366f4f77f5a186d7f553046022100ECA204AC03B6463ADB04B591B9DA4348C4B49BCDEFA7C66E4BD8CFBB08D17CB4022100FB7D3E61A472AE295919E76762DA94D38AA151F9859DC91C80F0A3E503113D30`,
  },
  {
    name: 'Compound Uniswap',
    symbol: 'cUNI',
    unit: '8',
    contractAddress: '0x35A18000230DA775CAc24873d00Ff85BccdeD550',
    signature: `080463554e4900000035A18000230DA775CAc24873d00Ff85BccdeD5503044022062FBF730D3B317A285AE85DADC9E06CFE6131DB124934136BAF48D2DAC19581502207B37FE1DE9A3520F04C20DDBC39479351B23E5F61A4548F55CCFC85E90052761`,
  },
  {
    name: 'Balancer',
    symbol: 'BAL',
    unit: '18',
    contractAddress: '0xba100000625a3754423978a60c9317c58a424e3d',
    signature: `120342414c00000000ba100000625a3754423978a60c9317c58a424e3d3046022100BD4B7A3D62627A6201F4FA5A9AD213629F768521492FC9F9F9561BE874AAEBAB0221009E73D53C25BC34FD4F41C96255F733D956AD012BD400F7F47043E52365032B35`,
  },
  {
    name: 'Numeraire',
    symbol: 'NMR',
    unit: '18',
    contractAddress: '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671',
    signature: `12034e4d52000000001776e1f26f98b1a5df9cd347953a26dd3cb466713045022100B0E279D30DA5180CC78D3C5164F31758280EA8C9A64A07B7276E738AC8523B9B022024A289727F75026956D8AAE68D40229603AFA3930AA8660188FB09230F3159B5`,
  },
  {
    name: 'Golem Network Token',
    symbol: 'GLM',
    unit: '18',
    contractAddress: '0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429',
    signature: `1203474c4d000000007DD9c5Cba05E151C895FDe1CF355C9A1D5DA642930460221008E6D00DB4B9DDB3FF90B6CA0D18B6746D9804D4875212E53753FC27E41CE2E650221009BDC57060C7EC0C427352839F3409C386725B5B38B65FE707F59EE7FCA300258`,
  },
  {
    name: 'Energy Web Token Bridged',
    symbol: 'EWTB',
    unit: '18',
    contractAddress: '0x178c820f862b14f316509ec36b13123da19a6054',
    signature: `120445575442000000178c820f862b14f316509ec36b13123da19a60543045022100B5B22B43AE6ABC61D33FD81ED6507C7BF230CC72F9653BF802077A791F2B10B702204660F2E21ACCDB2AB33E2F61CDC0DDD70A0C750857BF6153D82B1E144A7BBE42`,
  },
  {
    name: 'CelerToken',
    symbol: 'CELR',
    unit: '18',
    contractAddress: '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667',
    signature: `120443454c520000004f9254c83eb525f9fcf346490bbb3ed28a81c6673045022100BEDF4753915B98BFE099A2AD0C6C514D5F088D5ABD82B9C60F037BB2F53DA83F0220614D54ECC51FE11CD867CBA3BD9FA3B9AE864ACB879D32DA1DB81FB5D6E434FD`,
  },
  {
    name: 'Injective Token',
    symbol: 'INJ',
    unit: '18',
    contractAddress: '0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30',
    signature: `1203494e4a00000000e28b3B32B6c345A34Ff64674606124Dd5Aceca303045022100DA0DD613EBC53BAE769B570C668232E221E3305E5144B731B39953E920F6E89C0220709BB8A3A247DE0A28616DF6BCBE2115E4BEB5DBEEEAC14F96D372D5655AD799`,
  },
  {
    name: 'Gnosis',
    symbol: 'GNO',
    unit: '18',
    contractAddress: '0x6810e776880c02933d47db1b9fc05908e5386b96',
    signature: `1203474e4f000000006810e776880c02933d47db1b9fc05908e5386b96304502204573F3D17655DF4389D623513E3445620AC6854114704523F212728D312EF6F0022100AB579B5F103557FE5190A1D3F177F839475AFBE68C50A2F694D89590FB504437`,
  },
  {
    name: 'Paxos Gold',
    symbol: 'PAXG',
    unit: '18',
    contractAddress: '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
    signature: `12045041584700000045804880De22913dAFE09f4980848ECE6EcbAf783045022100AB4CC3CECDA6DE3DD8F60D806D7D977E63227F556254F52715EF08E7F579633502206628AA1821702DD75E53E4E8FA51C782A15F9C76FBF94443EC436A7840B2045D`,
  },
  {
    name: 'NKN',
    symbol: 'NKN',
    unit: '18',
    contractAddress: '0x5cf04716ba20127f1e2297addcf4b5035000c9eb',
    signature: `12034e4b4e000000005cf04716ba20127f1e2297addcf4b5035000c9eb3046022100E9DA75D279322CE5BA31BCBAD9C3F738507E043E5694B190D9F7BF8EC067BBC7022100CAE0D2F6755AF531D84AEB57735D5607C4C520F6EF47FBBEEE9204E0005BE286`,
  },
  {
    name: 'IoTeX Network',
    symbol: 'IOTX',
    unit: '18',
    contractAddress: '0x6fb3e0a217407efff7ca062d46c26e5d60a14d69',
    signature: `1204494f54580000006fb3e0a217407efff7ca062d46c26e5d60a14d693046022100C1899C6DA050DDE80482E702BDA13397FC1917E918E1CC23A06275049A7C4E6902210096F536214F8D9FCA822507E1EF5558A9793F64283BD3B38E753080474DBA38AA`,
  },
  {
    name: 'Ocean Token',
    symbol: 'OCEAN',
    unit: '18',
    contractAddress: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
    signature: `12054f4345414e0000967da4048cD07aB37855c090aAF366e4ce1b9F483046022100B1630CDA5C297DF675CBDD0EE029886E87618FEE6F7BA5917DCBF2D207E681DD0221009148501708D5DC50850A105FF70E610F2352BDEBF7BF39D9034BF64FDF6C0FBB`,
  },
  {
    name: 'SingularityNET Token',
    symbol: 'AGIX',
    unit: '8',
    contractAddress: '0x5b7533812759b45c2b44c19e320ba2cd2681b542',
    signature: `0804414749580000005b7533812759b45c2b44c19e320ba2cd2681b5423044022001C40EA8164625E5C456670364C37F780FDCDE6F7B4DB2F7CBA73C844315612E02207616D22B3A5AEC6A4B16C28A151D8DFCB5E00F9A691005052FA6D865678E5BBA`,
  },
  {
    name: 'WAX Token',
    symbol: 'WAX',
    unit: '8',
    contractAddress: '0x39bb259f66e1c59d5abef88375979b4d20d98022',
    signature: `08035741580000000039bb259f66e1c59d5abef88375979b4d20d980223044022006C417AF2BA71810C62CBA26A37D58E693279FF5E641EC2134E0477431F5AB4B02202C738D672802226D9FC54C0CA08F3132ADFDC84A6EDA710564DAB738E7A7BB8C`,
  },
  {
    name: 'Synth sUSD',
    symbol: 'sUSD',
    unit: '18',
    contractAddress: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    signature: `12047355534400000057ab1ec28d129707052df4df418d58a2d46d5f5130450220191068CD91B4C2C0B8006D850E89A1F4C39025115FFEE0BAF1FC903FB3435C50022100F4E200C5040CCA01DAC839043EDB26D57D7916F54E5E2E425C274C4623A54A27`,
  },
  {
    name: 'Token Prometeus Network',
    symbol: 'PROM',
    unit: '18',
    contractAddress: '0xfc82bb4ba86045af6f327323a46e80412b91b27d',
    signature: `120450524f4d000000fc82bb4ba86045af6f327323a46e80412b91b27d3045022100A78719CCA91931E4577BF6E141BE5B8504996F752461E273B153A9B5AA36EBA30220041C49E28B5CEC7803A6025910A90E022FF6A92D9723FEE60D2BB6E55E1B2A70`,
  },
  {
    name: 'Rocket Pool',
    symbol: 'RPL',
    unit: '18',
    contractAddress: '0xb4efd85c19999d84251304bda99e90b92300bd93',
    signature: `120352504c00000000b4efd85c19999d84251304bda99e90b92300bd9330450220336466C1E2696A2BE009020F23712ED1B33B75DE2E3C7B6CAD6F977BE50E092D022100A9AAC3CF2FABE7EBCAC0AB43621CE3FA1BBFF76161A76F82EB557DB1C8AC37EB`,
  },
  {
    name: 'AlphaToken',
    symbol: 'ALPHA',
    unit: '18',
    contractAddress: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
    signature: `1205414c5048410000a1faa113cbe53436df28ff0aee54275c13b409753045022100A3479E02D94C19A477C6116226206F7083FA0CC2CF1CC077400976D95479C0C3022036739CFA951E46D5C39E75B0BDFD35089A50CA630DC81B3E6E9140E0A3722B00`,
  },
  {
    name: 'OriginToken',
    symbol: 'OGN',
    unit: '18',
    contractAddress: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26',
    signature: `12034f474e000000008207c1ffc5b6804f6024322ccf34f29c3541ae263045022100811C84470AD56A15A85778B91999EB8C3FBD1EE8D028FA02C710B80821228C690220617EE7C3743AAF55B954615DBCE074840E82084409F59A2FCE424061546FD550`,
  },
  {
    name: 'FunFair',
    symbol: 'FUN',
    unit: '8',
    contractAddress: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
    signature: `080346554e00000000419d0d8bdd9af5e606ae2232ed285aff190e711b3046022100B875BD46FB3FAB36DEAD457CCB45C47D69560C9275C7985165773EE8A69CBB8A022100AB9FA8D5FCCEE4800365E42BF7F4F783663BD47F43F610EC07463FCBD2A112D6`,
  },
  {
    name: 'Orchid',
    symbol: 'OXT',
    unit: '18',
    contractAddress: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
    signature: `12034f5854000000004575f41308EC1483f3d399aa9a2826d74Da13Deb304502210089F1591907B6ACBDC1A286FA4FD4BCC7ACC8D9AF4E19FDC74F3BCEDFBE634FB7022058E565422C84D07056C09ED96E2E3C9D198AC95930C587C22D8B3DA6FD6D8C73`,
  },
  {
    name: 'Serum',
    symbol: 'SRM',
    unit: '6',
    contractAddress: '0x476c5E26a75bd202a9683ffD34359C0CC15be0fF',
    signature: `060353524d00000000476c5E26a75bd202a9683ffD34359C0CC15be0fF30440220157B56DF7B4466A9FB4F9BAAAB4C6575027F0B665C9E0EB623BA14DA46BE04BF022072DD0BBB947117FD0D958E1553BA22D507C0C20E3F43FFC9CD3A251B41C462A8`,
  },
  {
    name: 'SAND',
    symbol: 'SAND',
    unit: '18',
    contractAddress: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
    signature: `120453414e440000003845badAde8e6dFF049820680d1F14bD3903a5d03044022020AB7DE02467CC7D79F99CFD7D7E7B1AEB776ABC855ABBED1099D7FF6B4747A302206F49DE0F588AFD8915ED9D6BFAB4D498BE33C48E8DADA3D6D6D8D3184CF4AE5D`,
  },
  {
    name: 'StormX',
    symbol: 'STMX',
    unit: '18',
    contractAddress: '0xbe9375c6a420d2eeb258962efb95551a5b722803',
    signature: `120453544d58000000be9375c6a420d2eeb258962efb95551a5b7228033046022100C66FE5F116B2ADCA41342ABD680AD6FAFFCC5A7B9860980DEB34259F4AD8D6C6022100DC05709C686C1F9EA5F32CDDD6DB294A56D88474A39F98A519C9DA5F709C50DE`,
  },
  {
    name: 'Chromia',
    symbol: 'CHR',
    unit: '6',
    contractAddress: '0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2',
    signature: `0603434852000000008A2279d4A90B6fe1C4B30fa660cC9f926797bAA230450221008E6ED98C7E503006B50137BC3C88A56E583250BADA8D7739D7B17933754AD71302200DDF1EFEFDA5CF30A80EDF6FA3DF93493A0F806A2A821D7FB884C8A931CFF6FF`,
  },
  {
    name: 'BNS Token',
    symbol: 'BNS',
    unit: '8',
    contractAddress: '0x695106ad73f506f9d0a9650a78019a93149ae07c',
    signature: `0803424e5300000000695106ad73f506f9d0a9650a78019a93149ae07c3044022053C79362CE06B8B469F26C018CB4BCC8F8014A0019409DE9D53424543B8ECDD402206926F3568023985FFE582091E2EDEE332090FA55FD1C3BC267E8DBE9C06BFF32`,
  },
  {
    name: 'ApeCoin',
    symbol: 'APE',
    unit: '18',
    contractAddress: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
    signature: `1203415045000000004d224452801ACEd8B2F0aebE155379bb5D594381304502205C2BE6C14C017FE3B8BE2D9F11BF5A843C8180DD78A1BB75B55BAB0816B6BE35022100DDC9B9F21D4F305B1FF2EA990DF466DFC46B2764DFD4BFDD91F741EE23766B76`,
  },
  {
    name: 'BitTorrent-New',
    symbol: 'BTT',
    unit: '18',
    contractAddress: '0xC669928185DbCE49d2230CC9B0979BE6DC797957',
    signature: `120342545400000000C669928185DbCE49d2230CC9B0979BE6DC79795730460221009CACC75A762519997E270ADC807241D7F435F0A3405B604AF2B421F25060D4CB0221008EF2D7BDFEABBD362FCBE95951DA777CECFCA95D0DFAA92D7A7D2C9E33361632`,
  },
  {
    name: 'Deeper Network',
    symbol: 'DPR',
    unit: '18',
    contractAddress: '0xf3AE5d769e153Ef72b4e3591aC004E89F48107a1',
    signature: `120344505200000000f3AE5d769e153Ef72b4e3591aC004E89F48107a13044022008CA796BFCBE173ACB29B9AF5DB09ADD2E53A73CE523FFB116C36E80F3F7646602205A293B31D50F038D01B2791745E4AF24CA0505869D4BD359FFA5626AC8648711`,
  },
  // Matic
  {
    name: 'Matic Token',
    symbol: 'MATIC',
    unit: '18',
    contractAddress: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    signature: `12054d4154494300007D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB03045022100AF7459CA7028FE91E7C0C53F63AD994777BA7D60EB3958B0C54DEF91FDACD1AA02202A61407BD308C6C6621F2116CD69C6F888D70154DFE019561E19C3685DC73849`,
  },
  {
    name: 'Blur',
    symbol: 'BLUR',
    unit: '18',
    contractAddress: '0x5283D291DBCF85356A21bA090E6db59121208b44',
    signature: `1204424c55520000005283D291DBCF85356A21bA090E6db59121208b443045022062ef9bf66c331f4c6b6665d68e154779b4200a7473c398e81bd939fd9c0e35a302210097871b568eee3afe4883cb6d4b915c5775b12a477628694026d469c597597ff8`,
  },
  {
    name: 'Verse',
    symbol: 'VERSE',
    unit: '18',
    contractAddress: '0x249ca82617ec3dfb2589c4c17ab7ec9765350a18',
    signature: `120556455253450000249ca82617ec3dfb2589c4c17ab7ec9765350a18304402203B74A16298C75A24CE4F06EA9215FE3538C98F4B3BFBD7582B117F09122F6DA8022012E51FDC629FAD38841ACED9180FC17EC3E1013B406321E5DF061121933B57C6`,
  },
  {
    name: 'Worldcoin',
    symbol: 'WLD',
    unit: '18',
    contractAddress: '0x163f8c2467924be0ae7b5347228cabf260318753',
    signature: `1203574c4400000000163f8c2467924be0ae7b5347228cabf26031875330450221008d2458edc184c7d92ea7c346d7ae9a6d72cf6b2107bafd5988c5f8fe2eded5ce02202aa5c6a351b18a6f4c3a24f526953ce26edef7acefb8338f5857018219c43a9b`,
  },
  {
    name: 'Origin Ether',
    symbol: 'OETH',
    unit: '18',
    contractAddress: '0x856c4efb76c1d1ae02e20ceb03a2a6a08b0b8dc3',
    signature: `12044F455448000000856C4EFB76C1D1AE02E20CEB03A2A6A08B0B8DC33045022030610B479289FF2BE9609D41C442E9360F5294D6D340DFB9044D2314C1ECC3D1022100E24DEFF561606EDECEFDA4BCD20FD668088CAC7DA0245B4F54D93E8AE2CA8454`,
  },
];
// signature : [unit-1B][symbol_length-1B][symbol-7B][contractAddress-20B][signature]
