export const COIN_TYPE = '8000003c';
export const CHAIN_ID = 56;

export const TRANSFER = {
  script:
    '03000601C707000000003CCC0710C0C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF6CC071094CAA02700C2A2D700FFF6CC071080CC0E1038C2E09700CC07C0028080C3709710DC07C003425343DC07C003424E42CC0FC0023078BAA02F6C0E04DDF09700DAA2D7C0FFF612D207CC05065052455353425554546F4E',
  signature:
    '00304502206A9D1E267D9AC65B28FFB49286F73041D3FF3834F68C5CAB1A700607C57DB052022100BB46FD3AB7A402AF163FA90EB0348A3AE14CF51E4CA4364931104CD1996F99E6',
};

export const BEP20 = {
  script:
    '03000601C707000000003CCC07C002F800C2ACD70034FFF8C2A5D700FFF6C2ACD7002AFFF6CC071094CAAC270045CC07C01380B844a9059cbb000000000000000000000000CAA02700CC07200000000000000000000000000000000000000000CAA2C7000CCC0E1038C2E09700CC07C0028080C37097C002DC07C00342534311ACC7CC3C1D04591507C004CC0F104012AC17C03D0401071507C002FF00B5AC17003DCAACBF003EDEF09700250F00CC0FC0023078BAA02F6C0E04DDF0970012AC17C03C0400141507C002FF00B5AC17003CDAA2C7B00CD207CC05065052455353425554546F4E',
  signature:
    '00304502202B33814A04EE43EFC342DD3345652DAF34606EAD6599EF1A3C45F78727CC7283022100E20E6E30C3D5B8E04B5DD5C90FBFE064077CA9FCBF2689DDD2020FF51A1D69EE',
};

export const BSCSmartContract = {
  script:
    '03000601C707000000003CCC07C002F800C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF6CC071094CAA02700C2A2D700FFF6C2AC97003ACC0E1038C2E09700CC07C0028080C37097C002DC07C003425343D207C005534d415254D207CC05065052455353425554546f4e',
  signature:
    '000030440220429DF67EB2A0D1ED5681F912FCCE313C457829D7A76123B59F427E94A2FD8B0A02204FCC18E46AB820323D2CA5ED52FCEAA5DFFF70A3BF2DC4D060E30CFDCAE08D99',
};

export const BSCSmartContractSegment = {
  script:
    '03050601C707000000003CA00700C2ACD70032FFF8C2ACD7001EFFF6C2ACD70028FFF6CC071094CAA02700C2A2D700FFF6C4ACC7003A04CC0E1038C2E09700CC07C0028080BE0710DC07C003425343D207C005534D415254D207CC05065052455353425554546F4E',
  signature:
    '00003044022027a77af545abe0084cb55bafb2e295f361f76ef9636fc7879ff16358d113ffb3022067a27e1b08a24f67ce9f221181825fb7b9b04a163ec56acc286d32ebe3d01e01',
};

export const SIGN_MESSAGE = {
  script:
    '03000601C707000000003CCC07C01A19457468657265756D205369676E6564204D6573736167653A0ACAA09700DC07C003425343D207C0074D455353414745D207CC05065052455353425554546F4E',
  signature:
    '0000304402204C3BC0B32823EE53EBAA156DA9C6145029652E42D6FF1CEFF777FEC37738920C02200C52F02969FD648F160CCEC52D61403C854B694ABB059C17C70FBDA245E49CCC',
};

export const SIGN_TYPED_DATA = {
  script:
    '03000601C707000000003CCC07C0021901CAA057005AA597C006DC07C003425343D207CC0504545950454444415441D207CC05065052455353425554546f4e',
  signature:
    '003045022008935AF6BA11B9F720E59BE61AFF6F62A7A48FF2A39863AFD8B3920F355A1265022100E81EA86AC2FA3864CBC8773B10AF550B91F6A0E1FB68512DF32D8D35BC9FF3C8',
};
